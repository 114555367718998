.typewriter-container {
  position: relative;
  display: inline-block;
  background-color: black;
  font-family: "Inter Tight", sans-serif;
  
}

.background-image {
  width: 100%;
  height: auto;
  display: block;
  opacity: 0.4;

}

.typewriter-text {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 150%;
  color: white;
  white-space: nowrap;
  /* font-size: 500%; */
}

.relative-container {
  position: relative;
}

.about-text {
  position: absolute;
  top: 50%;
  color: white;
  font-size: 34px;
  text-align: center;
  font-family: "Inter Tight", sans-serif;

}


 @media  (min-width: 575.98px){
  .typewriter-text{
    font-size: 500%;
  }
  
 }
 @media (max-width: 575.98px) {
  .about-text{
    font-size: 12px;
    font-family: "Inter Tight", sans-serif;
  }
 }