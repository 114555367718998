@media (max-width: 575.98px) {
    #head{
        font-size:x-large;
    }
    
}

.skill {
    display: inline-block;
    margin: 0 40px;
    text-align: center;
   color: white;
  }
  
  .skill-icon {
    width: 80px; /* adjust size as needed */
    height: 80px; /* adjust size as needed */
    transition: transform 0.3s ease-in-out;
    border-radius:30% ;
    background-color: transparent;
  }
  
  .skill-label {
    margin-top: 5px;
  }
  
  /* Define animation */
  .skill:hover .skill-icon {
    transform: scale(1.2); /* Adjust scaling factor as needed */
  }
  