

  .im1 img {
    width: 340px; /* Adjust the width as needed */
    height: 200px;
    align-items: center;
    box-shadow: 10px 10px rgb(235, 231, 231);
    mix-blend-mode:hard-light;
    border-radius: 10px solid white;
  }
  /* projectsss.css */

.im1 img {
    transition: transform 0.3s ease; /* Add smooth transition */
  }
  
  .im1 img:hover {
    transform: scale(1.1); /* Zoom effect on hover */
  }

  .ppprr{
    left: 5%;
    position: relative;
    font-family: "Inter Tight", sans-serif;
  }



@media (max-width: 540px) {
    .row {
      flex-wrap: wrap;
      justify-content: center;
    }
    .row > div {
      flex: 0 0 50%;
      max-width: 50%;
      width: 150px;
    }
    .im1 img {
        width: 150px; /* Adjust the width as needed */
        height: 140px
      }

    .ic{
      padding-bottom: 10px;
    }
    .mechh{
      padding-right: 2px;
    }
    .bead{
      /* right: 2px; */
      right: 15px;
      position: relative;
    }
  }
  